<template>
  <!-- 剧本录入 -->
  <div class="entry">
    <!-- 头部栏 -->
    <el-card class="entry-headers">
      <div class="headers-content">
        <el-button
          type="primary"
          plain
          size="small"
          icon="el-icon-arrow-left"
          @click="back2ZP"
        >
        </el-button>
        <span style="font-weight: bold">{{
          scenarioName ? scenarioName : "新剧本"
        }}</span>
        <span>
          <el-button
            style="
              border: 1px solid rgba(197, 192, 224, 1);
              background: rgba(197, 192, 224, 1);
              color: #333;
            "
            type="warning"
            size="mini"
            >上传</el-button
          >
          <el-button
            style="
              border: 1px solid rgba(250, 225, 238, 1);
              background: rgba(250, 225, 238, 1);
              color: #333;
            "
            type="warning"
            size="mini"
            >未保存</el-button
          >
        </span>
      </div>
    </el-card>

    <!-- 内容栏 -->
    <div class="entry-content">
      <div class="content">
        <span class="row1"> 1 </span>
        <div class="row2">
          <div class="row2-left">
            <el-card class="row2-left-card">
              <div v-for="(item, i) in navigationList" :key="i">
                <div
                  :class="
                    type === item.id
                      ? 'navigation-item-choose'
                      : 'navigation-item'
                  "
                  @click="choiceType(item)"
                >
                  {{ item.name }}
                </div>
                <transition name="el-zoom-in-top">
                  <div
                    v-if="type === item.id && item.id === 2"
                    class="item-people"
                  >
                    <div
                      :class="`people ${
                        peopleId === item.id ? 'people-choice' : ''
                      }`"
                      v-for="(item, i) in peopleList"
                      :key="i"
                      @click="choicePeople(item)"
                    >
                      <div>{{ item.personName || "未命名" }}</div>
                    </div>

                    <div
                      v-if="peopleList.length < $store.state.playNum"
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <el-button
                        @click="choicePeople({ id: 0 })"
                        type="text"
                        icon="el-icon-circle-plus-outline"
                      >
                        添加
                      </el-button>
                    </div>
                  </div>
                </transition>
              </div>
            </el-card>
          </div>

          <div class="row2-right">
            <router-view
              @afterSave="afterSave"
              @updateScenarioName="updateScenarioName"
              @afterAddPeople="afterAddPeople"
              :peopleId="peopleId"
              :pScenarioId="scenarioId"
              :key="peopleId"
            ></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scenarioId: "", // 剧本ID
      scenarioName: "",
      type: 1,
      navigationList: [
        {
          id: 1,
          name: "基本设定",
          routerName: "entryBase",
        },
        {
          id: 2,
          name: "人物设定",
          routerName: "entryPeople",
        },
        {
          id: 3,
          name: "搜证设定",
          routerName: "entryEvidence",
        },
      ],
      peopleList: [],
      peopleId: "",
    };
  },
  created() {
    this.type = this.$route.meta.sort
    this.scenarioName = sessionStorage.scenarioName || '新剧本'
    this.showTip();
    this.scenarioId = localStorage.getItem("scenarioId");
    if (this.scenarioId) {
      this.getPersonList();
    }
  },
  methods: {
    updateScenarioName(v) {
      this.scenarioName = v
      sessionStorage.scenarioName = v
    },
    back2ZP() {
      this.$router.replace({
        path: "/home/works",
      });
    },
    showTip() {
      let showTipFlag = sessionStorage.getItem("showTipFlag") == "true";
      setTimeout(() => {
        if (showTipFlag) {
          this.$confirm("剧本已通过审核，请慎重修改", {
            confirmButtonText: "确定",
            distinguishCancelAndClose: true,
            showCancelButton: false,
          });
        }
      }, 500);
    },
    choiceType(item) {
      if (item.id !== 1 && !this.scenarioId) {
        this.$notify({
          title: "提示",
          message: "请先保存基本设定",
          type: "warning",
        });
      } else {
        this.type = item.id;
        this.$router.push({
          name: item.routerName,
        });
      }
      this.showTip();
    },
    choicePeople(item) {
      // 切换人物
      this.peopleId = item.id;
    },
    async getPersonList() {
      const res = await this.getRequest.getPersonList(this.scenarioId);
      this.peopleList = res.datas;
      if (this.peopleList.length > 0) {
        this.peopleId = this.peopleList[0].id;
      }
    },
    afterSave(state, id, scenarioName) {
      this.scenarioId = id;
      this.scenarioName = scenarioName;
      this.type = 2;
      this.getPersonList();
    },
    async afterAddPeople(id) {
      const res = await this.getRequest.getPersonList(this.scenarioId);
      this.peopleList = res.datas;
      this.peopleId = id;
    },
  },
};
</script>

<style lang='scss' scoped>
.entry {
  .entry-headers {
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .headers-content {
      width: 1366px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .entry-content {
    min-height: calc(100vh - 70px);
    width: 100%;
    padding-top: 70px;
    background: rgba(248, 247, 255, 1);
    display: flex;
    justify-content: center;
    .content {
      width: 1366px;
      .row1 {
        opacity: 0;
      }
      .row2 {
        margin-top: 10px;
        display: flex;
        height: calc(100vh - 60px - 150px);
        .row2-left {
          width: 20%;
          height: 100%;
          .row2-left-card {
            padding: 15px;
            height: 100%;
            .navigation-item {
              margin-top: 12px;
              padding: 12px;
              color: rgba(153, 153, 153, 1);
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .navigation-item-choose {
              margin-top: 12px;
              background: rgba(127, 118, 189, 1);
              padding: 12px;
              color: #fff;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .item-people {
              background: rgba(250, 249, 255, 1);
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .people {
                cursor: pointer;
                min-width: 100px;
                text-align: center;
                margin: 5px 0;
                padding: 4px;
                border-radius: 4px;
                font-size: 14px;
              }
              .people-choice {
                background: rgba(236, 234, 250, 1);
                font-weight: bold;
              }
            }
          }
        }
        .row2-right {
          width: calc(80% - 24px);
          margin-left: 24px;
          height: 100%;
        }
      }
    }
  }
}
</style>